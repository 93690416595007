
import ApiRequestService from '@/services/ApiRequestService'
import SnippetsService from '@/services/SnippetsService'
import StorageService, { LANGUAGE_KEY, USER_ID } from '@/services/StorageService'
import Multiselect from "vue-multiselect";
import {
  debounceTime,
  distinctUntilChanged,
  map,
} from "rxjs/operators";
import { Subject } from "rxjs";
import Vue from "vue";
import LanguagesService from "@/services/LanguagesService";
import Editor from "@tinymce/tinymce-vue";

export default {

  components: {
    Multiselect,
    'editor': Editor
  },
  data() {
    let snippets = SnippetsService.getSnippetsByNamespaceAndLanguage( 'Users', StorageService.getFromStorage( LANGUAGE_KEY ) );

    return {
      page: 0,
      pageCount: 0,
      itemsPerPage: 10,
      notificationTemplatesSnippet: snippets ?? {},
      notificationTemplatesList: [],
      notificationTemplatesPaginatedList: {},
      searchedNotificationTemplatesPaginatedList: {},
      searchedNotificationTemplatesList: [],
      paginatedNotificationTemplatesList: [],
      paginatedSearchedNotificationTemplatesList: [],
      spacesList: [],
      organizationsList: [],
      organizationsNamesList: [],
      loadedPages: [],
      searchedData: [],
      deletedItem: '',
      searchedDataOriginal: '',
      selectedOrganization: '',
      currentManagerOrganization: '',
      searchKey: '',
      searchField: '',
      dialog: false,
      dialogDelete: false,
      dialogUpgrade: false,
      submitted: false,
      editedIndex: -1,
      editedItem: {
        id: '',
        key: '',
        title: '',
        body: '',
        language: '',
      },
      defaultItem: {
        id: '',
        key: '',
        title: '',
        body: '',
        language: '',
      },
      sortBy: 'id',
      editedPrefix: '',
      searchResults: '',
      search$: new Subject(),
      sortDesc: true,
      errorKey: '',
      pagesCount: 1,
      currentPage: 1,
      disableKeyField: true,
      oldNotificationTemplate: {},
      addressesDataList: [],
      itemsPerPageOptions: [ 0 ],
      notificationTemplateData: { notificationTemplates: [], count: Number },
      spaceData: [],
      totalPassengers: 0,
      numberOfPages: 0,
      passengers: [],
      loading: true,
      canOverwrite: true,
      languages: [],
      language: '',
      defaultLanguageKey: '',
    }
  },

  computed: {

    formTitle() {
      return this.editedIndex === -1 ? this.notificationTemplatesSnippet.add_notificationTemplate : this.notificationTemplatesSnippet.edit_notificationTemplate
    },

    headers() {
      return [
        { text: this.notificationTemplatesSnippet.id, value: 'id', width: '5%', sortable: true },
        { text: this.notificationTemplatesSnippet.key, value: 'key', width: '10%', sortable: true },
        { text: this.notificationTemplatesSnippet.title, value: 'title', width: '15%', sortable: true },
        {
          text: this.notificationTemplatesSnippet.language,
          value: 'language',
          width: '10%',
          sortable: true
        },
        {
          text: this.notificationTemplatesSnippet.text,
          value: 'body',
          maxWidth: '500px',
          width: '45%',
          sortable: true
        },
        { text: this.notificationTemplatesSnippet.actions, value: 'actions', width: '10%', sortable: false },
      ]
    },
  },

  watch: {

    dialog( val ) {
      val || this.close()
    },

    dialogDelete( val ) {
      val || this.closeDelete()
    },

    dialogUpgrade( val ) {
      val || this.closeUpgrade()
    },

    async searchKey( searchNotificationTemplate ) {
      if ( searchNotificationTemplate ) {
        this.search$.next( searchNotificationTemplate );
        this.searchField = searchNotificationTemplate
      } else {
        this.search$.next( '' );
        this.searchField = ''
        this.searchedData = [];
      }
    },

  },
  beforeRouteLeave( to, from, next ) {
    this.saveNotificationTemplatesDataToSessionStorage();
    // StorageService.deleteNotificationTemplateFromStorage();
    next()
  },

  async mounted() {
    this.searchAction();

    window.addEventListener( 'beforeunload', this.saveNotificationTemplatesDataToSessionStorage );
    let storageNotificationTemplates = this.getNotificationTemplatesDataFromSessionStorage();
    if ( storageNotificationTemplates?.data ) {
      this.notificationTemplateData = storageNotificationTemplates;
      this.loadedPages.push( +storageNotificationTemplates.currentPage );
      await this.getNotificationTemplatesPaginated();
    } else {
      await this.getNotificationTemplatesApi();
    }

    this.languages = LanguagesService.languages;
    if ( !this.languages ) {
      new LanguagesService().setLanguages().then( ( languages ) => {
        this.languages = languages.map( i => i.key )
        this.defaultLanguageKey = this.languages[0];
      } );
    } else {
      this.languages = this.languages.map( i => i.key )
      this.defaultLanguageKey = this.languages[0];
    }
    this.loading = false;

  },

  methods: {
    customSort( items ) {
      return items
    },

    async sortTable() {
      await Promise.resolve();
      this.changePage( this.currentPage ?? 1 );
    },

    getSortQuery( comesFirst = false ) {
      if ( this.sortBy ) {
        return `${ comesFirst ? '?' : '&' }sortBy=${ this.sortBy }&direction=${ this.sortDesc ? 'DESC' : 'ASC' }`;
      }

      return '';
    },

    saveNotificationTemplatesDataToSessionStorage() {
      //TODO: remove
      return
      if ( this.canOverwrite ) {
        sessionStorage.setItem( 'notificationTemplates_paginated_list', JSON.stringify( this.notificationTemplateData ) )
        sessionStorage.setItem( 'notificationTemplates_searched_notificationTemplate', JSON.stringify( this.searchField ) )
        sessionStorage.setItem( 'notificationTemplates_searched_list', JSON.stringify( this.searchedDataOriginal ) )
        sessionStorage.setItem( 'notificationTemplates_paginated_list_save_date', Date.now().toString() )
      }
    },

    getNotificationTemplatesDataFromSessionStorage() {
      let notificationTemplatesData = JSON.parse( sessionStorage.getItem( 'notificationTemplates_paginated_list' ) );
      this.searchKey = JSON.parse( sessionStorage.getItem( 'notificationTemplates_searched_notificationTemplate' ) );
      if ( this.searchKey ) {
        notificationTemplatesData = {};
      }
      this.currentPage = notificationTemplatesData?.currentPage ? +notificationTemplatesData.currentPage : 1;
      return notificationTemplatesData;
    },


    openDetails( notificationTemplate ) {
      if ( notificationTemplate && notificationTemplate.id ) {
        this.$router.push( `notification-template-details/${ notificationTemplate.id }` )
      }
    },

    searchAction() {
      this.search$ = new Subject();

      this.search$.pipe(
        map( notificationTemplate => notificationTemplate?.toString()?.toLowerCase() ),
        debounceTime( 1000 ),
        distinctUntilChanged()
      ).subscribe( async ( term ) => {
        this.currentPage = 1;

        if ( this.searchField === '' ) {
          await this.getNotificationTemplatesPaginated();
          return
        }

        let data = await this.searchNotificationTemplate( term );
        await this.getSearchedNotificationTemplatesPaginated( data );
      } )
    },

    searchNotificationTemplate( term ) {
      return ApiRequestService.getRequest( `api/admin-notification?searchText=${ term }${ this.getSortQuery() }` );
    },

    async changePage( page ) {
      this.notificationTemplatesPaginatedList = {};
      this.searchedNotificationTemplatesPaginatedList = {};
      this.paginatedSearchedNotificationTemplatesList.length = 0;
      this.paginatedNotificationTemplatesList.length = 0;
      this.notificationTemplatesList.length = 0;
      this.currentPage = page;

      if ( this.searchField ) {
        this.searchedDataOriginal = await ApiRequestService.getRequest( `api/admin-notification?page=${ page }&searchText=${ this.searchField }${ this.getSortQuery() }` );


        if ( this.searchedDataOriginal?.data?.length ) {
          await this.setSearchedNotificationTemplatesToNotificationTemplatesList( this.searchedDataOriginal.data );
        }
        return;
      }

      this.notificationTemplateData = await ApiRequestService.getRequest( `api/admin-notification?page=${ page }${ this.getSortQuery() }` );
      this.loadedPages = [ this.currentPage ];

      if ( this.notificationTemplateData?.data?.length ) {
        await this.setNotificationTemplatesToNotificationTemplatesList( this.notificationTemplateData.data );
      }
    },

    async getNotificationTemplatesApi() {
      this.notificationTemplateData = await ApiRequestService.getRequest( `api/admin-notification${ this.getSortQuery( true ) }` );
      await this.getNotificationTemplatesPaginated();
    },

    async getNotificationTemplatesPaginated() {
      this.pagesCount = this.notificationTemplateData.totalPages
      if ( this.pagesCount ) {
        await this.setNotificationTemplatesToNotificationTemplatesList( this.notificationTemplateData.data );
      }
    },

    async getSearchedNotificationTemplatesPaginated( data ) {
      this.pagesCount = Math.ceil( data.totalElements / 10 )

      this.searchedNotificationTemplatesList = [];
      this.paginatedSearchedNotificationTemplatesList.length = 0;
      this.searchedNotificationTemplatesPaginatedList = {};
      await this.setSearchedNotificationTemplatesToNotificationTemplatesList( data.data );

      this.searchedDataOriginal = data;
      this.searchedData = this.getObjClone( this.searchedNotificationTemplatesList )
    },

    async setNotificationTemplatesToNotificationTemplatesList( notificationTemplateData ) {
      notificationTemplateData.forEach( ( notificationTemplate, index ) => {
        let existsIndex = this.notificationTemplatesList.findIndex( item => item.id === notificationTemplate?.id );

        let notificationTemplateData = {
          id: notificationTemplate?.id,
          key: notificationTemplate.key,
          title: notificationTemplate.title,
          body: notificationTemplate.body,
          language: notificationTemplate.language,
        };
        if ( existsIndex !== -1 ) {
          Vue.set( this.notificationTemplatesList, existsIndex, notificationTemplateData )

          let values = this.notificationTemplatesPaginatedList[this.currentPage] ? this.notificationTemplatesPaginatedList[this.currentPage].values() : [];
          let notificationTemplatesPaginatedListClone = [ ...values ];
          notificationTemplatesPaginatedListClone.forEach( item => {
            if ( item?.id === notificationTemplateData?.id ) {
              this.notificationTemplatesPaginatedList[this.currentPage].delete( item )
              this.notificationTemplatesPaginatedList[this.currentPage].add( notificationTemplateData )
            }
          } );
          return;
        }

        Vue.set( this.notificationTemplatesList, this.notificationTemplatesList.length, notificationTemplateData )

        if ( !this.notificationTemplatesPaginatedList[this.currentPage] ) {
          this.notificationTemplatesPaginatedList[this.currentPage] = new Set();
        }
        if ( this.notificationTemplatesPaginatedList[this.currentPage].size < 10 ) {
          this.notificationTemplatesPaginatedList[this.currentPage].add( notificationTemplateData );
        } else {
          this.notificationTemplatesPaginatedList[this.currentPage].delete( [ ...this.notificationTemplatesPaginatedList[this.currentPage] ][9] );
          this.notificationTemplatesPaginatedList[this.currentPage].add( notificationTemplateData );
        }
      } )
      if ( this.notificationTemplatesPaginatedList[this.currentPage] ) {
        this.paginatedNotificationTemplatesList = this.getObjClone( [ ...this.notificationTemplatesPaginatedList[this.currentPage] ] )
      }
    },

    async setSearchedNotificationTemplatesToNotificationTemplatesList( data ) {
      data.forEach( ( notificationTemplate, index ) => {
        let existsIndex = this.paginatedSearchedNotificationTemplatesList.findIndex( item => item.id === notificationTemplate?.id );
        let notificationTemplateData = {
          id: notificationTemplate?.id,
          language: notificationTemplate?.language,
          key: notificationTemplate?.key,
          title: notificationTemplate?.title,
          body: notificationTemplate?.body,
          createDate: this.getToLocaleDate( notificationTemplate?.createDate ),
        };

        if ( existsIndex !== -1 ) {
          let searchedNotificationTemplatesPaginatedListClone = this.getObjClone( this.searchedNotificationTemplatesPaginatedList[this.currentPage] )

          searchedNotificationTemplatesPaginatedListClone.forEach( ( item, index ) => {
            if ( item?.id === notificationTemplateData?.id ) {
              this.searchedNotificationTemplatesPaginatedList[this.currentPage][index] = notificationTemplateData
            }
          } );
          return;
        }

        if ( !this.searchedNotificationTemplatesPaginatedList[this.currentPage] ) {
          this.searchedNotificationTemplatesPaginatedList[this.currentPage] = [];
        }
        if ( this.searchedNotificationTemplatesPaginatedList[this.currentPage].length < 10 ) {
          this.searchedNotificationTemplatesPaginatedList[this.currentPage].push( notificationTemplateData );
        }
      } )

      if ( this.searchedNotificationTemplatesPaginatedList[this.currentPage] ) {
        if(this.deletedItem[0]){
          this.notificationTemplatesPaginatedList[this.currentPage] = new Set([...this.notificationTemplatesPaginatedList[this.currentPage]].filter(item => item.id !== this.deletedItem[0].id))
        }
        this.paginatedSearchedNotificationTemplatesList = this.getObjClone( this.searchedNotificationTemplatesPaginatedList[this.currentPage] )
      }
    },

    getToLocaleDate( date ) {
      let options = {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      }
      return new Date( date ).toLocaleDateString( 'en-US', options ) ?? 'null'
    },

    addNotificationTemplateToNotificationTemplateList( data ) {
      let newNotificationTemplateData = {
        id: data?.id,
        language: data?.language,
        key: data?.key,
        title: data?.title,
        body: data?.body,
      };

      if ( this.searchField ) {
        let existsIndex = this.paginatedSearchedNotificationTemplatesList?.findIndex( notificationTemplate => notificationTemplate.id === newNotificationTemplateData.id );
        if ( existsIndex !== -1 ) {
          this.paginatedSearchedNotificationTemplatesList[existsIndex] = newNotificationTemplateData;
        } else {
          this.paginatedSearchedNotificationTemplatesList.unshift( newNotificationTemplateData );
          this.searchedDataOriginal.data.totalElements++;
        }

        this.setSearchedNotificationTemplatesToNotificationTemplatesList( this.paginatedSearchedNotificationTemplatesList )

      } else {
        let existsIndex = this.notificationTemplateData?.data?.findIndex( notificationTemplate => notificationTemplate.id === newNotificationTemplateData.id );
        if ( existsIndex !== -1 ) {
          this.notificationTemplateData.data[existsIndex] = newNotificationTemplateData;
        } else {
          this.notificationTemplateData.data.unshift( newNotificationTemplateData );
          this.notificationTemplateData.totalElements++;
        }

        this.setNotificationTemplatesToNotificationTemplatesList( this.notificationTemplateData.data )
      }
    },

    getChangedData( oldNotificationTemplate ) {
      const dataFormUpdate = {}
      for ( let [ key, value ] of Object.entries( this.editedItem ) ) {
        if ( oldNotificationTemplate[key] === this.editedItem[key] ) {
          continue
        }


        dataFormUpdate[key] = this.editedItem[key]
      }
      return dataFormUpdate
    },

    dataIsValid() {
      const keyIsValid = this.notificationTemplateIsValid( this.editedItem?.key?.trim() )
      const isNotValid = []

      if ( keyIsValid !== true ) {
        isNotValid.push( keyIsValid )
      }


      if ( isNotValid.length ) {
        return isNotValid
      }

      return true
    },


    notificationTemplateIsValid( v ) {
      return ( v && v.length > 0 ) || this.notificationTemplatesSnippet.required
    },

    deleteItem( item ) {
      let list = this.searchField ? this.paginatedSearchedNotificationTemplatesList : this.paginatedNotificationTemplatesList;
      this.editedIndex = list.findIndex( notificationTemplate => notificationTemplate.id === item.id );
      this.editedItem = this.getObjClone( list[this.editedIndex] );
      this.dialogDelete = true
    },


    deleteItemConfirm() {
      let paginatedList = this.searchField ? this.paginatedSearchedNotificationTemplatesList : this.paginatedNotificationTemplatesList;
      let list = this.searchField ? this.searchedNotificationTemplatesList : this.notificationTemplatesList;
      paginatedList.splice( this.editedIndex, 1 )
      list.splice( this.editedIndex, 1 )
      this.deletedItem = this.notificationTemplateData.data[this.editedIndex];
      ApiRequestService.deleteRequest( `api/admin-notification/${ this.editedItem.id }` ).then()

      this.closeDelete()
    },

    getObjClone( obj ) {
      return JSON.parse( JSON.stringify( obj ) )
    },

    close() {
      this.dialog = false
      this.submitted = false
      this.errorKey = ''

      /**
       * if action is edit, set to form old data
       */
      let data
      if ( this.editedIndex !== -1 ) {
        data = this.getObjClone( this.oldNotificationTemplate )
      } else {
        data = this.getObjClone( this.defaultItem )
      }

      for ( let [ key, value ] of Object.entries( data ) ) {
        if ( value && typeof value === 'object' ) {
          for ( let [ valueKey, valueValue ] of Object.entries( value ) ) {
            this.$set( value, valueKey, valueValue )
          }
        } else {
          this.$set( this.editedItem, key, value )
        }
      }

      this.$nextTick( () => {
        this.editedItem = { ...this.getObjClone( this.defaultItem ) }
        this.editedIndex = -1
      } )
    },

    closeDelete() {
      this.dialogDelete = false
      this.submitted = false
      this.$nextTick( () => {
        this.editedItem = { ...this.getObjClone( this.defaultItem ) }
        this.editedIndex = -1
      } )
    },

    setNewNotificationTemplateToNotificationTemplatesList( notificationTemplateData ) {
      this.addNotificationTemplateToNotificationTemplateList( notificationTemplateData )
      this.close()
    },

    async saveNotificationTemplate() {
      let isValid = this.dataIsValid()
      if ( isValid !== true ) {
        return
      }

      if ( !this.editedItem.language ) {
        this.editedItem.language = this.defaultLanguageKey;
      }

      let dataToCreate = {};
      for ( let [ key, value ] of Object.entries( this.editedItem ) ) {
        if ( value ) {
          dataToCreate[key] = value;
        }
      }

      const notificationTemplateData = await ApiRequestService.postRequest( 'api/admin-notification', dataToCreate );
      if ( notificationTemplateData?.errors ) {
        this.errorKey = notificationTemplateData?.errors;
        return;
      } else if ( notificationTemplateData?.errorMessage ) {
        this.errorKey = notificationTemplateData?.errorMessage;
        return;
      }
      if ( !notificationTemplateData ) {
        return;
      }
      this.setNewNotificationTemplateToNotificationTemplatesList( notificationTemplateData )

    },

    save() {
      this.submitted = true

      this.saveNotificationTemplate()
    },

    selectLanguage( language ) {
      this.editedItem.language = language;
    },
  },
}

